import React, { useEffect } from 'react';
import { RouterProvider } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ConfigProvider, Empty, message } from 'antd';
import { getPersistor } from '@rematch/persist';
import { PersistGate } from 'redux-persist/es/integration/react';
import Loading from '@/components/PageLoading';
import useBrandHandler from '@/hooks/useBrandHandler';
import routers from '@/routes';

import { store } from './store';

import '@/common/antdOverride.less';

message.config({
  maxCount: 1,
  top: 40,
});

function App() {
  const { CURRENT_BRAND, getMainTheme } = useBrandHandler();
  const antdTheme = getMainTheme();
  console.log(`CURRENT_BRAND`, CURRENT_BRAND);

  useEffect(() => {
    store.dispatch.app.getAllEnums();
  }, []);

  return (
    <ConfigProvider theme={antdTheme} renderEmpty={() => <Empty />}>
      <Provider store={store}>
        <PersistGate loading={<Loading />} persistor={getPersistor()}>
          <RouterProvider router={routers} fallbackElement={<Loading />} />
        </PersistGate>
      </Provider>
    </ConfigProvider>
  );
}

export default App;
