import la from '../LazyComponent';

const LeadManagement = la(() => import('@/pages/LeadManagement'));
const LeadManagementRoutes = [
  {
    path: '/leads',
    element: LeadManagement,
    name: 'menu.Leads',
    meta: {
      key: 'menu.sws.lead-management',
    },
  },
];
export default LeadManagementRoutes;
