export const enum AU_REGULATORS {
  VFSC = 'vfsc',
  VFSC2 = 'vfsc2',
  CIMA = 'cima',
  FCA = 'fca',
  ASIC = 'asic',
}
export const enum PU_REGULATORS {
  SVG = 'svg',
  FSA = 'fsa',
}

export const enum VT_REGULATORS {
  SVG = 'svg',
}

export const enum STAR_REGULATORS {
  SVG = 'svg',
}

export const BREAD_INDEX = {
  au: 'AU',
  pug: 'PUG',
  vt: 'VT',
  star: 'STAR',
};

// favicon
// logo
export const BRAND_LOGO = {
  [BREAD_INDEX.au]: require('@/assets/au/logo.png'),
  [BREAD_INDEX.pug]: require('@/assets/pug/logo.png'),
  [BREAD_INDEX.vt]: require('@/assets/vt/logo.png'),
  [BREAD_INDEX.star]: require('@/assets/star/logo.png'),
};

// logo black
export const BRAND_BLACK_LOGO = {
  [BREAD_INDEX.au]: require('@/assets/au/logo.png'),
  [BREAD_INDEX.pug]: require('@/assets/pug/logo-black.png'),
  [BREAD_INDEX.vt]: require('@/assets/vt/logo.png'),
  [BREAD_INDEX.star]: require('@/assets/star/logo.png'),
};

// small logo
export const BRAND_CONSTRICT_LOGO = {
  [BREAD_INDEX.au]: require('@/assets/au/logo-constrict.png'),
  [BREAD_INDEX.pug]: require('@/assets/pug/logo-constrict.png'),
  [BREAD_INDEX.vt]: require('@/assets/vt/logo-constrict.png'),
  [BREAD_INDEX.star]: require('@/assets/star/logo-constrict.png'),
};
// login logo
export const BRAND_LOGIN_LOGO = {
  [BREAD_INDEX.au]: require('@/assets/au/sws-login-logo.png'),
  [BREAD_INDEX.pug]: require('@/assets/pug/sws-login-logo.png'),
  [BREAD_INDEX.vt]: require('@/assets/vt/sws-login-logo.png'),
  [BREAD_INDEX.star]: require('@/assets/star/sws-login-logo.png'),
};

// regulator list
export const BRAND_REGULATOR = {
  [BREAD_INDEX.au]: [
    { label: 'VFSC', value: AU_REGULATORS.VFSC },
    { label: 'VFSC2', value: AU_REGULATORS.VFSC2 },
    { label: 'CIMA', value: AU_REGULATORS.CIMA },
    { label: 'FCA', value: AU_REGULATORS.FCA },
    { label: 'ASIC', value: AU_REGULATORS.ASIC },
  ],
  [BREAD_INDEX.pug]: [
    { label: 'SVG', value: PU_REGULATORS.SVG },
    { label: 'FSA', value: PU_REGULATORS.FSA },
  ],
  [BREAD_INDEX.vt]: [{ label: 'SVG', value: VT_REGULATORS.SVG }],
  [BREAD_INDEX.star]: [{ label: 'SVG', value: STAR_REGULATORS.SVG }],
};

// video for tutorial
export const BRAND_VIDEO = {
  [BREAD_INDEX.au]: require('@/assets/videos/video-AU.mp4'),
  [BREAD_INDEX.pug]: require('@/assets/videos/video-PU.mp4'),
  [BREAD_INDEX.vt]: require('@/assets/videos/video-VT.mp4'),
  [BREAD_INDEX.star]: require('@/assets/videos/video-STAR.mp4'),
};

// favicon
export const BRAND_FAVICON = {
  [BREAD_INDEX.au]: require('@/assets/au/favicon.ico'),
  [BREAD_INDEX.pug]: require('@/assets/pug/logo-constrict-black.png'),
  [BREAD_INDEX.vt]: require('@/assets/vt/favicon.ico'),
  [BREAD_INDEX.vt]: require('@/assets/star/favicon.ico'),
};

export const TRAD_ARCHIVE_TIME = {
  [BREAD_INDEX.au]: '2024-3-24',
  [BREAD_INDEX.pug]: '2024-3-24',
  [BREAD_INDEX.vt]: '2024-3-24',
  [BREAD_INDEX.star]: '2024-05-10',
};

export const TRAD_NOTIONAL_VALUE_TIME = {
  [BREAD_INDEX.au]: '2022-02-22',
  [BREAD_INDEX.pug]: '2022-02-22',
  [BREAD_INDEX.vt]: '2022-02-22',
  [BREAD_INDEX.star]: '2024-05-10',
};

// antd theme
export const BRAND_THEME = {
  [BREAD_INDEX.au]: {
    token: {
      colorPrimary: '#3F6FFB',
      colorLink: '#3F6FFB',
      colorError: '#CD2A2A',
    },
    components: {
      Table: {
        cellPaddingBlock: 4,
        headerSplitColor: 'transparent',
      },
    },
  },
  [BREAD_INDEX.pug]: {
    token: {
      colorPrimary: '#006BFF',
      colorLink: '#006BFF',
      colorError: '#EE5253',
    },
    components: {
      Table: {
        cellPaddingBlock: 4,
      },
    },
  },
  [BREAD_INDEX.vt]: {
    token: {
      colorPrimary: '#3F6FFB',
      colorLink: '#3F6FFB',
      colorError: '#CD2A2A',
    },
    components: {
      Table: {
        cellPaddingBlock: 4,
      },
    },
  },
  [BREAD_INDEX.star]: {
    token: {
      colorPrimary: '#3F6FFB',
      colorLink: '#3F6FFB',
      colorError: '#CD2A2A',
    },
    components: {
      Table: {
        cellPaddingBlock: 4,
      },
    },
  },
};
