import { createModel } from '@rematch/core';
import type { RootModel } from '.';
import { getLocalRcLoginStatus } from '@/utils/ringCentral';

export const ringCentral = createModel<RootModel>()({
  state: {
    rcAuthMessage: {
      clientId: '',
      clientSecret: '',
    },
    showRCbutton: !getLocalRcLoginStatus(), // 默认显示
  },
  reducers: {
    setRcAuthMessage: (state, payload) => {
      return {
        ...state,
        rcAuthMessage: payload,
      };
    },
    setShowRCbutton: (state, payload) => {
      return {
        ...state,
        showRCbutton: payload,
      };
    },
  },
  effects: (dispatch) => ({}),
});
