import dayjs from 'dayjs';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
// import { emoji } from '@/constant/regexp';

dayjs.extend(quarterOfYear);

// const ua = navigator.userAgent;

export const matchLandscape = () => {
  return window.matchMedia('(orientation: landscape)').matches;
};

export const uaMobile = () => {
  return (
    window.navigator &&
    window.navigator?.userAgent.match(
      /(ipad|ipod|iphone|android|coolpad|mmp|smartphone|midp|wap|xoom|symbian|j2me|blackberry|wince)/i
    ) != null
  );
};

export const matchMobile = () => {
  const isMobile = window.matchMedia('(max-width: 767px)').matches;
  const isLandscape = matchLandscape();
  if (isMobile) {
    if (isLandscape) {
      return false;
    }
    return true;
  }
  return false;
};

export const mobileOrLandscape = () => {
  const isLandscape = matchLandscape();
  return uaMobile() || (uaMobile() && isLandscape);
};

export const deepClone = (obj, hash = new WeakMap()) => {
  if (obj === null) return obj; // 如果是null或者undefined就不进行拷贝操作
  if (obj instanceof Date) return new Date(obj);
  if (obj instanceof RegExp) return new RegExp(obj);
  // 可能是对象或者普通的值  如果是函数的话是不需要深拷贝
  if (typeof obj !== 'object') return obj;
  // 是对象的话就要进行深拷贝
  if (hash.get(obj)) return hash.get(obj);
  const cloneObj = new obj.constructor();
  // 找到的是所属类原型上的constructor,而原型上的 constructor指向的是当前类本身
  hash.set(obj, cloneObj);
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      // 实现一个递归拷贝
      cloneObj[key] = deepClone(obj[key], hash);
    }
  }
  return cloneObj;
};

export const formatDate = (date: string, format: string = 'YYYY-MM-DD HH:mm') => {
  if (!date) return '';
  return dayjs(date).format(format);
};

/**
 * Get first tag content from a string with markup based on tag param
 * @param {string} input - string with markup
 * @param {string} tag - tag name
 * @param {string} escaped - wheteher input markup is escaped
 * @returns {string} content wrapped inside tag
 */
export const getMarkupContent = (input = '', tag: string, escaped = false): string => {
  const leftArrow = escaped ? '&lt;' : '<';
  const rightArrow = escaped ? '&gt;' : '>';
  const matchReg = new RegExp(
    `${leftArrow}${tag}${rightArrow}(.*?)${leftArrow}${tag}${rightArrow}`,
    'gi'
  );
  const replaceReg = new RegExp(`${leftArrow}?${tag}${rightArrow}`, 'gi');
  const reg = input.match(matchReg);
  return reg ? reg[0].replace(replaceReg, '') : '';
};

export const isOverTime = (date) => {
  if (date) {
    const now = new Date().getTime();
    const overTime = new Date(date).getTime();
    if (now >= overTime) {
      return true;
    }
  }
  return false;
};

/**
 *
 * @param param {key:value}
 * @return {string}
 */
export const formatSearch = (param) => {
  if (!param || (param && Object.keys(param).length === 0)) {
    return '';
  }
  const searchArgs = Object.entries(param).map((e) => {
    return e[1] ? `${e[0]}:${e[1]}` : '';
  });
  const resultArgs = searchArgs.filter((e) => e !== '') || [];
  return resultArgs.join(' ');
};

export const debounce = (fn, delay = 1000) => {
  let timer: NodeJS.Timeout | null = null;
  return () => {
    if (timer) clearTimeout(timer);
    timer = setTimeout(fn, delay);
  };
};

export const moneyFormat = (num: number | string, decimal = 2) => {
  if (num === 0) {
    return 0;
  }
  if (!num) {
    return '--';
  }
  // 保留两位小数，直接截断并补零
  const arrNum = num.toString().split('.');
  if (!/\./.test(num.toString())) {
    let zeroNum = '';
    for (let index = 0; index < decimal; index++) {
      zeroNum += '0';
    }
    num += `.${zeroNum}`;
  } else if (arrNum.length > 1 && arrNum[1].length < decimal) {
    let dotZeroNum = '';
    for (let index = 0; index < decimal - arrNum[1].length; index++) {
      dotZeroNum += '0';
    }
    num += dotZeroNum;
  } else {
    num = num.toString();
    num = num.substring(0, num.indexOf('.') + decimal + 1);
  }
  // 千位分隔符
  const res = num.toString().replace(/\d+/, function (n) {
    // 先提取整数部分F
    return n.replace(/(\d)(?=(\d{3})+$)/g, function ($1) {
      return `${$1},`;
    });
  });
  return res;
};

export const getNumberPoint = (number: number | string) => {
  const innerNumber = `${number}`;
  if (!innerNumber) return;
  return innerNumber.split('.');
};

export const isObject = (value) => {
  return Object.prototype.toString.call(value) === '[object Object]';
};

export const filterRequestEmpty = (data: object) => {
  const result = JSON.parse(JSON.stringify(data));

  for (const key in result) {
    if (
      typeof result[key] === 'undefined' ||
      result[key] === '' ||
      (Array.isArray(result[key]) && result[key].length === 0) ||
      result[key] === null ||
      (isObject(result[key]) && Object.keys(result[key].length === 0))
    ) {
      delete result[key];
    }
  }
  return result;
};

/**
 *
 * @param type {number} 必填
 * @param format {string} default: YYYY-MM-DD HH:mm:ss
 * @paramEnum
 * 1 本年
 * 2 本季度
 * 3 本月
 * 4 今日
 * 5 本周
 * @returns { object} {startTime:'',endTime:''}
 */
export const getTimeScale = (
  type: number | undefined,
  formatString: string = 'YYYY-MM-DD HH:mm:ss'
) => {
  if (!type) return;
  let startTime = '';
  let endTime = '';
  const now = new Date(); // 当前日期
  const nowYear = now.getFullYear(); // 当前年
  switch (type) {
    case 1:
      startTime = dayjs(new Date(nowYear, 0, 1)).format(formatString);
      endTime = dayjs(new Date()).format(formatString);
      break;
    case 2:
      startTime = dayjs().startOf('quarter').format(formatString);
      endTime = dayjs(new Date()).format(formatString);
      break;
    case 3:
      startTime = dayjs().startOf('month').format(formatString);
      endTime = dayjs(new Date()).format(formatString);
      break;
    case 4:
      startTime = `${dayjs(new Date()).format('YYYY-MM-DD')} 00:00:00`;
      endTime = dayjs(new Date()).format(formatString);
      break;
    case 5:
      const today = dayjs(new Date()).format(formatString);
      const lastWeekDay = dayjs().endOf('week').add(1, 'day').format(formatString);
      const diffCount = dayjs(new Date()) < dayjs().startOf('week').add(1, 'day');
      const diffCurrentTime = dayjs(new Date()) < dayjs().endOf('week').add(1, 'day');

      if (diffCount) {
        startTime = dayjs().startOf('week').subtract(6, 'day').format(formatString);
        endTime = today;
      } else {
        startTime = dayjs().startOf('week').add(1, 'day').format(formatString);
        endTime = diffCurrentTime ? today : lastWeekDay;
      }
      break;
    case 6:
      // 前7天
      startTime = dayjs(new Date()).subtract(7, 'day').format(formatString);
      endTime = dayjs(new Date()).format(formatString);
      break;
    case 7:
      // 前30天
      startTime = dayjs(new Date()).subtract(30, 'day').format(formatString);
      endTime = dayjs(new Date()).format(formatString);
      break;
    default:
      break;
  }
  return {
    startTime,
    endTime,
  };
};

export const getUid = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0,
      v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

export const formatStartAndEndTime = (dateTime: { startDate; endDate }, formatString?: string) => {
  const defaultFormat = formatString || 'YYYY-MM-DD';
  const { startDate, endDate } = dateTime;
  if (startDate && startDate) {
    return {
      startTime: formatString
        ? dayjs(startDate).format(formatString)
        : `${dayjs(startDate).format(defaultFormat)} 00:00:00`,
      endTime: formatString
        ? dayjs(endDate).format(formatString)
        : `${dayjs(endDate).format(defaultFormat)} 23:59:59`,
    };
  }
  return {
    startTime: '',
    endTime: '',
  };
};

export const setBodyOverFlow = (collapsed: boolean) => {
  const bodyElement = document.querySelector('body');
  if (collapsed) {
    bodyElement?.classList.add('no-scroll');
  } else {
    bodyElement?.classList.remove('no-scroll');
  }
};

export const xssTransferFn = (str) => {
  if (str)
    return str
      .replace(/&lt;/g, '<')
      .replace(/&gt;/g, '>')
      .replace(/&quot;/g, '"');
  // .replace(emoji, '');
};

// xss 转义函数
export const xssPreventTransferApi = (obj) => {
  // 如果传入的是数组，则遍历数组中的每个元素
  if (Array.isArray(obj)) {
    return obj.map((item) => {
      // 如果数组元素是对象或数组，则递归调用函数
      if (isObject(item)) {
        return xssPreventTransferApi(item);
      }
      // 处理转义字符
      if (typeof item === 'string') {
        item = xssTransferFn(item);
      }
      // 这里可以添加其他修改属性值的逻辑
      return item;
    });
  }
  // 如果传入的是对象
  if (isObject(obj)) {
    const newObj = {};
    // 遍历对象的每个属性
    Object.keys(obj).forEach((key) => {
      // 如果属性值是数组或对象，则递归调用函数
      if (Array.isArray(obj[key]) || isObject(obj[key])) {
        newObj[key] = xssPreventTransferApi(obj[key]);
      } else if (typeof obj[key] === 'string') {
        newObj[key] = xssTransferFn(obj[key]);
      } else {
        newObj[key] = obj[key];
      }
    });
    return newObj;
  }

  return obj;
};
