import la from '../LazyComponent';

const AdminStatistics = la(() => import('@/pages/AdminStatistics'));

const AdminStatisticsRoutes = [
  {
    path: '/adminStatistics',
    element: AdminStatistics,
    name: 'menu.AdminStatistics',
    meta: {
      key: 'menu.sws.admin-statistics',
    },
  },
];

export default AdminStatisticsRoutes;
