/** const disableMinimize = '&disableMinimize=1';
// const enableAnalytics = '&enableAnalytics=1';
const multipleTabsSupport = '&multipleTabsSupport=1';
*/

const enableReport = '&enableErrorReport=1';
const disableMessages = '&disableMessages=true';
const disableMeeting = '&disableMeeting=true';
const disableContacts = '&disableContacts=true';
const disableCallHistory = '&disableCallHistory=true';
const disableConferenceInvite = '&disableConferenceInvite=true';
const defaultCallWith = '&defaultCallWith=browser';

const disconnectInactiveWebphone = '&disconnectInactiveWebphone=1';

export const rcSetting = `${enableReport}${disableMessages}${disableMeeting}${disableCallHistory}${disableContacts}${disconnectInactiveWebphone}${disableConferenceInvite}${defaultCallWith}`;
export const setRCstoreMessage = (values) => {
  return localStorage.setItem('rcStoreMessage', JSON.stringify(values));
};

export const getRCstoreMessage = () => {
  const values = localStorage.getItem('rcStoreMessage');
  return values ? JSON.parse(values) : null;
};

export const setRCsessionMessage = (params) => {
  console.log(params, 'setRCsessionMessage');
  return sessionStorage.setItem('rcSessionMessage', JSON.stringify(params));
};

export const getRCsessionMessage = () => {
  const values = sessionStorage.getItem('rcSessionMessage');
  return values ? JSON.parse(values) : null;
};

export const getCurrentActiveCalls = () => {
  const values = sessionStorage.getItem('currentActive');
  return values ? JSON.parse(values) : null;
};

export const setCurrentActiveCalls = (val) => {
  return sessionStorage.setItem('currentActive', JSON.stringify(val));
};

export const clearMessage = (clearAll = false) => {
  const sessionStoreClientId = getRCsessionMessage();
  const storeMessage = getRCstoreMessage();
  if (sessionStoreClientId && sessionStoreClientId?.clientId && storeMessage) {
    delete storeMessage[sessionStoreClientId.clientId];
    setRCstoreMessage(storeMessage);
  } else {
    localStorage.setItem('rcStoreMessage', '');
  }
  if (clearAll) {
    sessionStorage.setItem('rcSessionMessage', '');
  }
};

export const getSessionCallArgs = () => {
  const values = sessionStorage.getItem('callArgs');
  return values ? JSON.parse(values) : null;
};

export const setSessionCallArgs = (val, isDelete?: boolean) => {
  let callArgs = {};
  const sessionCallArgs = isDelete ? null : getSessionCallArgs();
  if (sessionCallArgs) {
    callArgs = sessionCallArgs || {};
  }
  return sessionStorage.setItem('callArgs', JSON.stringify({ ...callArgs, ...val }));
};

export const setLocalIsRCLogOut = (val: boolean | null) => {
  return localStorage.setItem('isRClogout', JSON.stringify(val));
};

export const getLocalIsRCLogOut = () => {
  const value = localStorage.getItem('isRClogout');
  return value ? JSON.parse(value) : null;
};

export const setLocalRcLoginStatus = (val: boolean | null) => {
  return localStorage.setItem('isRcLogin', JSON.stringify(val));
};

export const getLocalRcLoginStatus = () => {
  const value = localStorage.getItem('isRcLogin');
  return value ? JSON.parse(value) : null;
};

export const setSessionRcReady = (val: boolean | null) => {
  return sessionStorage.setItem('isRcReady', JSON.stringify(val));
};

export const getSessionRcReady = () => {
  const value = sessionStorage.getItem('isRcReady');
  return value ? JSON.parse(value) : null;
};

export const setSystemRcReady = (val: boolean | null) => {
  return localStorage.setItem('isRcSystemReady', JSON.stringify(val));
};

export const getSystemRcReady = () => {
  const value = localStorage.getItem('isRcSystemReady');
  return value ? JSON.parse(value) : null;
};

export const getUserIdByDirection = (direction: string) => {
  const currentUID = sessionStorage.getItem('currentUID');
  const currentUser = getRCsessionMessage();
  const currentId = getRCsessionMessage()?.clientId || getRCsessionMessage()?.salesNoteId;

  switch (direction) {
    case 'Outbound':
      return {
        callerUserId: currentUID,
        [currentUser?.salesNoteId ? 'calleeSalesNoteId' : 'calleeUserId']: currentId,
      };
    case 'Inbound':
      return {
        callerUserId: null,
        calleeUserId: currentUID,
      };
    default:
      return {
        callerUserId: currentUID,
        [currentUser?.salesNoteId ? 'calleeSalesNoteId' : 'calleeUserId']: currentId,
      };
  }
};

export const loadRCcallback = (clientId, clientSecret, handleCallback?: () => void) => {
  let isFlag = false;
  let rcScript: any = document.getElementById('rc-script');
  const styleUrl = `${window.location.origin}/rcStyle.css`;
  const initUrl = `https://apps.ringcentral.com/integration/ringcentral-embeddable/latest/adapter.js?stylesUri=${styleUrl}&clientId=${clientId}&clientSecret=${clientSecret}&prefix=sws-rc-widget${rcSetting}&zIndex=2024&`;
  if (rcScript) {
    rcScript.src = initUrl;
    return new Promise((resolve, reject) => {
      rcScript.onload = function () {
        isFlag = true;
        resolve(rcScript);
      };
      rcScript.onerror = function (error) {
        reject(error);
      };
      if (!isFlag) {
        handleCallback && handleCallback();
      }
    });
  }
  rcScript = document.createElement('script');
  rcScript.id = 'rc-script';
  rcScript.src = initUrl;
  const rcs0: any = document.getElementsByTagName('script')[0];
  rcs0.parentNode.insertBefore(rcScript, rcs0);
  return new Promise((resolve, reject) => {
    rcScript.onload = function () {
      isFlag = true;
      resolve(rcScript);
    };
    rcScript.onerror = function (error) {
      reject(error);
    };
    if (!isFlag) {
      handleCallback && handleCallback();
    }
  });
};
