import { useEffect } from 'react';
import { useLocation, useNavigate, matchRoutes, useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { COOKIE_TOKEN_NAME, PERMISSION, PERMISSION_MAP } from '@/constant';
import { cookie } from '@/utils/cookie';
import routes from '@/routes';
import { RootState } from '@/store';

// 路由守卫
const BeforeRouter = ({ element }: { element: JSX.Element }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  const [searchParams] = useSearchParams();
  const { loginState } = useSelector((state: RootState) => state.app);
  const token = cookie.get(COOKIE_TOKEN_NAME);
  const PERMISSION_LIST = JSON.parse(localStorage.getItem(PERMISSION) || '[]');

  // admin跳转入口，走自己的登录逻辑
  const whiteList = ['/otherEntry'];
  const redirectHomeRouters = ['/login', '/', ''];

  const isEmpty = (val) => {
    return val === 'null' || val === undefined || val === 'undefined' || val === '' || !val;
  };

  const permissionValidate = () => {
    const newRouterPaths: any[] = matchRoutes(routes?.routes || [], location) || [];
    const current: any = [...newRouterPaths].pop();
    const from = searchParams.get('from');
    const key = current?.route?.meta?.key || '';
    if (from === 'lead' && PERMISSION_LIST?.includes('menu.sws.lead-management')) {
      return '';
    }
    if (key && !PERMISSION_LIST?.includes(key)) {
      return (window.location.href = '/403');
    }
  };

  const onRouterBefore = () => {
    // debugger;
    if (loginState || !isEmpty(token)) {
      // 已登录
      if (redirectHomeRouters.includes(pathname)) {
        return PERMISSION_MAP.get(PERMISSION_LIST[0]);
      }
      permissionValidate();
    } else if (!whiteList.includes(pathname)) {
      // 未登录
      return '/login';
    }
    return '';
  };

  useEffect(() => {
    // debugger;

    const nextPath = onRouterBefore() || '';
    if (nextPath && nextPath !== pathname) {
      navigate(nextPath, { replace: true });
    }
  }, [pathname]);

  return element;
};

export default BeforeRouter;
