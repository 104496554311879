import { Models } from '@rematch/core';
import { app } from './app';
import { ringCentral } from './ringCentral';
import { historyTabs } from './historyTabs';

export interface RootModel extends Models<RootModel> {
  app: typeof app;
  ringCentral: typeof ringCentral;
  historyTabs: typeof historyTabs;
}

export const models: RootModel = { app, ringCentral, historyTabs };
