import React, { lazy, Suspense } from 'react';
import Loading from '@/components/PageLoading';
import BeforeRouter from './BeforeRouter';

function LazyComponent(importFunc) {
  const LazyComp = lazy(importFunc);
  return (
    <BeforeRouter
      element={
        <Suspense fallback={<Loading />}>
          <LazyComp />
        </Suspense>
      }
    />
  );
}

export default LazyComponent;
