export const cookie = {
  get(keys) {
    const mat = new RegExp(`(^|[^a-z])${keys}=(.*?)(;|$)`, 'i').exec(document.cookie);
    return mat ? decodeURIComponent(mat[2]) : '';
  },
  set(name, value, expires?: any, path?: string, domain?: string, secure?: string) {
    let cookieText = `${encodeURIComponent(name)}=${encodeURIComponent(value)}`;
    // 设置最大有效期
    const maxTime = Number.MAX_SAFE_INTEGER;
    if (expires instanceof Date) {
      cookieText += `; expires=${expires.toUTCString()}`;
    } else {
      const exp = new Date();
      exp.setTime(exp.getTime() + maxTime);
      cookieText += `; expires=${exp.toUTCString()}`;
    }
    if (path) {
      cookieText += `; path=${path}`;
    }
    if (domain) {
      cookieText += `; domain=${domain}`;
    }
    if (secure) {
      cookieText += '; secure';
    }
    document.cookie = cookieText;
  },
  unset(name, path, domain, secure) {
    this.set(name, null, new Date(0), path, domain, secure);
  },
  delete(name, path?: string, domain?: string) {
    this.set(name, null, -1, path, domain);
  },
};

export const getRefreshTokenCookie = (cname) => {
  const name = `${cname}=`;
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    // debugger;
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }

    if (c.indexOf(name) === 0 && !c.includes('Bearer')) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
};
